import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/layouts/blogPost.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We`}{`’`}{`re thrilled to announce that Uniswap v3 has been deployed to the Ethereum mainnet! All relevant contract addresses can be found `}<a parentName="p" {...{
        "href": "https://github.com/Uniswap/uniswap-v3-periphery/blob/main/deploys.md"
      }}>{`here`}</a>{`.`}</p>
    <p>{`Uniswap v3 is the most powerful version of the protocol yet, with Concentrated Liquidity offering unprecedented capital efficiency for liquidity providers, better execution for traders, and superior infrastructure at the heart of decentralized finance.`}</p>
    <p>{`For a full description of v3`}{`’`}{`s features, please refer to our announcement `}<a parentName="p" {...{
        "href": "https://uniswap.org/blog/uniswap-v3/"
      }}>{`blog pos`}</a><a parentName="p" {...{
        "href": "https://uniswap.org/blog/uniswap-v3/"
      }}>{`t`}</a>{`. Audit reports for the core and periphery contracts can be found `}<a parentName="p" {...{
        "href": "https://github.com/Uniswap/uniswap-v3-core/tree/main/audits"
      }}>{`here`}</a>{` and `}<a parentName="p" {...{
        "href": "https://github.com/Uniswap/uniswap-v3-periphery/tree/main/audits/abdk"
      }}>{`here`}</a>{`. After 6 weeks, the Uniswap v3 bug bounty has returned no major findings. However, we would like to note that v3 is a complex new protocol and we cannot guarantee all bugs have been found and resolved.`}</p>
    <h1 {...{
      "id": "traders",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#traders",
        "aria-label": "traders permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Traders`}</h1>
    <p>{`The swap interface now routes trades through Uniswap v3 and will alert you when a better exchange rate is available on Uniswap v2.`}</p>
    <p>{`The Uniswap analytics platform — `}<a parentName="p" {...{
        "href": "https://info.uniswap.org"
      }}>{`info.uniswap.org`}</a>{` — now tracks v3 performance. You can continue to review `}<a parentName="p" {...{
        "href": "http://v2.info.uniswap.org/"
      }}>{`v2 stats`}</a>{` here.`}</p>
    <h1 {...{
      "id": "liquidity-providers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#liquidity-providers",
        "aria-label": "liquidity providers permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Liquidity providers`}</h1>
    <p>{`The pool interface now supports the creation of Uniswap v3 positions with multiple fee tiers and concentrated liquidity ranges. Each position is represented as an NFT and comes with a unique piece of on-chain generative art. Look out for rare sparkles!`}</p>
    <p><img parentName="p" {...{
        "src": "/2923c8b006cecffc76302f53569d38e5/NFT1.gif",
        "alt": null
      }}></img></p>
    <p>{`We have also released a migration portal for Uniswap v2 and Sushiswap LPs to seamlessly migrate their assets over to v3. Due to the introduction of concentrated liquidity, the migration portal requires LPs to specify a fee tier and price range for their allocated liquidity. A migration walkthrough guide can be found `}<a parentName="p" {...{
        "href": "https://docs.uniswap.org/concepts/introduction/liquidity-user-guide"
      }}>{`here`}</a>{`.`}</p>
    <h1 {...{
      "id": "developers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#developers",
        "aria-label": "developers permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Developers`}</h1>
    <p>{`Developers can begin building on Uniswap v3 immediately!`}</p>
    <p>{`Initial documentation and example projects can be found `}<a parentName="p" {...{
        "href": "https://docs.uniswap.org/reference/smart-contracts"
      }}>{`here`}</a>{`. Additionally, the Uniswap Labs team will be available to answer integration-related questions in our Discord`}{`’`}{`s #integrations channel.`}</p>
    <h1 {...{
      "id": "contact",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#contact",
        "aria-label": "contact permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Contact`}</h1>
    <p>{`The Uniswap Labs team will be available to answer questions in the Uniswap community Discord.`}</p>
    <p>{`Follow the `}<a parentName="p" {...{
        "href": "https://twitter.com/uniswap"
      }}>{`Uniswap Labs Twitter account`}</a>{` for more updates!`}</p>
    <h1 {...{
      "id": "faq",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#faq",
        "aria-label": "faq permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`FAQ:`}</h1>
    <p><strong parentName="p">{`1. What happens to the Uniswap v2 protocol?`}</strong></p>
    <p>{`The Uniswap v2 Protocol will remain functional and available for use as long as the Ethereum network continues to exist. However, we expect that over time the advantages of Uniswap v3 will draw a majority of liquidity and trading volume away from v2.`}</p>
    <p><strong parentName="p">{`2. Do I have to migrate my liquidity?`}</strong></p>
    <p>{`You are not required to migrate your v2 liquidity if you so choose. The Uniswap v2 protocol will remain active and functional in perpetuity. However, it may be advantageous to migrate liquidity as we expect a majority of trading volume — and, consequently, LP fee generation — to take place on v3.`}</p>
    <p><strong parentName="p">{`3. Will the updated Uniswap interface only execute trades through v3?`}</strong></p>
    <p>{`The updated interface will default to executing on v3 but will always alert you if a better trade is available on v2.`}</p>
    <p><strong parentName="p">{`4. Is there a v3 liquidity mining program?`}</strong></p>
    <p>{`There is no v3 liquidity mining program scheduled at launch. Uniswap governance may introduce a liquidity mining program at any time through the standard Uniswap governance process.`}</p>
    <hr></hr>
    <p>{`To get involved and stay up to date:`}</p>
    <ul>
      <li parentName="ul">{`Join the Uniswap community `}<a parentName="li" {...{
          "href": "https://discord.gg/FCfyBSbCU5"
        }}>{`discord`}</a></li>
      <li parentName="ul">{`Follow Uniswap on `}<a parentName="li" {...{
          "href": "https://twitter.com/Uniswap"
        }}>{`Twitter`}</a></li>
      <li parentName="ul">{`Subscribe to the Uniswap `}<a parentName="li" {...{
          "href": "/blog"
        }}>{`blog`}</a></li>
      <li parentName="ul">{`Register as a delegate `}<a parentName="li" {...{
          "href": "http://sybil.org/"
        }}>{`Sybil`}</a></li>
      <li parentName="ul">{`Participate in `}<a parentName="li" {...{
          "href": "http://gov.uniswap.org/"
        }}>{`Uniswap governance`}</a></li>
    </ul>
    <p>{`Uniswap Team
🦄`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      